import React from 'react';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import _ from 'lodash';
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';

import { RootState } from '../../Modules/reducers';
import { OrderWithProducts, SelectedOrderTab } from '../../models';
import { setCreditCard, setSelectedTab, setTarp, setTaxExempt } from '../../Modules/orders/actions';
import SelectedOrderEdit from './SelectedOrderEdit';
import SelectedOrderPickup from './SelectedOrderPickup';
import CustomerViewAutoHide from '../../SWH/CustomerViewAutoHide';
import numeral from 'numeral';

interface LocalProps {
  orderId: number;
}

interface LocalState {
}

interface StateProps {
  isEditing: boolean;
  isLoadingOrderDetails: boolean;
  activeTab: SelectedOrderTab;
  selectedOrder: OrderWithProducts | null;
}

interface DispatchProps {
  setTarp: typeof setTarp;
  setTaxExempt: typeof setTaxExempt;
  setCreditCard: typeof setCreditCard;
  setSelectedTab: typeof setSelectedTab;
}

type Props = LocalProps & StateProps & DispatchProps;

class SelectedOrder extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      isPrintingOrder: false,
    };
  }

  public render () {
    const { activeTab, isEditing, selectedOrder } = this.props;

    if (!selectedOrder) {
      return null;
    }

    const pickupTabId = 'pickupTab';

    return (
      <>
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex flex-row child-spacing-x-2 flex-wrap">
                <div className="d-flex child-spacing-x-1 flex-row">
                  <div><strong>Order Id:</strong></div>
                  <div>{selectedOrder.orderId}</div>
                </div>

                <div className="d-flex child-spacing-x-1 flex-row">
                  <div><strong>Customer Name:</strong></div>
                  <div>{selectedOrder.name}</div>
                </div>

                <div className="d-flex child-spacing-x-1 flex-row">
                  <div><strong>Num Items:</strong></div>
                  <div>{selectedOrder.numItems}</div>
                </div>

                <CustomerViewAutoHide>
                  {!selectedOrder.isTaxExempt && (
                    <>
                      <div className="d-flex child-spacing-x-1 flex-row">
                        <div><strong>Sub Total:</strong></div>
                        <div>{selectedOrder.displayTotalPrice}</div>
                      </div>

                      <div className="d-flex child-spacing-x-1 flex-row">
                        <div><strong>Tax:</strong></div>
                        <div>{selectedOrder.displayTotalTax}</div>
                      </div>

                      <div className="d-flex child-spacing-x-1 flex-row">
                        <div><strong>Total:</strong></div>
                        <div>{selectedOrder.displayTotalWithTax}</div>
                      </div>
                    </>
                  )}

                  {selectedOrder.isTaxExempt && (
                    <>
                      <div className="d-flex child-spacing-x-1 flex-row">
                        <div><strong>Total:</strong></div>
                        <div>{selectedOrder.displayTotalPrice}</div>
                      </div>
                    </>
                  )}
                </CustomerViewAutoHide>
              </div>
              <div className="d-flex child-spacing-x-2">
                <Button
                  color={selectedOrder.tarp ? 'primary' : 'light'}
                  size="sm"
                  onClick={() => this.props.setTarp(selectedOrder.orderId, !selectedOrder.tarp)}
                >
                  <i className={classnames({ 'fa': true, 'fa-square-o': !selectedOrder.tarp, 'fa-check-square-o': selectedOrder.tarp })} />
                  {' '}Tarp {selectedOrder.tarpFee ? `${numeral(selectedOrder.tarpFee).format('$0,0.00')}` : ''}
                </Button>

                <Button
                  title={numeral(selectedOrder.baseCreditCardFee).format('0.00%')}
                  color={selectedOrder.creditCard ? 'primary' : 'light'}
                  size="sm"
                  onClick={() => this.props.setCreditCard(selectedOrder.orderId, !selectedOrder.creditCard)}
                >
                  <i className={classnames({ 'fa': true, 'fa-square-o': !selectedOrder.creditCard, 'fa-check-square-o': selectedOrder.creditCard })} />
                  {' '}Credit Card {selectedOrder.creditCardFee ? `${numeral(selectedOrder.creditCardFee).format('$0,0.00')}` : ''}
                </Button>

                <Button
                  color={selectedOrder.isTaxExempt ? 'primary' : 'light'}
                  size="sm"
                  onClick={() => this.props.setTaxExempt(selectedOrder.orderId, !selectedOrder.isTaxExempt)}
                >
                  <i className={classnames({ 'fa': true, 'fa-square-o': !selectedOrder.isTaxExempt, 'fa-check-square-o': selectedOrder.isTaxExempt })} />
                  {' '}Tax Exempt
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Nav className="mt-2" tabs>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === SelectedOrderTab.Edit })} onClick={() => this.props.setSelectedTab(SelectedOrderTab.Edit)}>
              Edit
            </NavLink>
          </NavItem>
          <NavItem data-tip data-for={pickupTabId}>
            <NavLink disabled={isEditing} className={classnames({ active: activeTab === SelectedOrderTab.Pickup })} onClick={() => this.props.setSelectedTab(SelectedOrderTab.Pickup)}>
              Pickup
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="edit" className="p-2">
            <Row>
              <Col md={12}>
                <SelectedOrderEdit orderDetails={selectedOrder} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="pickup">
            <Row>
              <Col md={12}>
                <SelectedOrderPickup isShowing={activeTab === SelectedOrderTab.Pickup} orderDetails={selectedOrder} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        {isEditing && (
          <ReactTooltip id={pickupTabId} place="right" type="info" effect="float" className="bg-danger">
            You must save before accessing the pickup tab.
          </ReactTooltip>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: LocalProps) => {
  const selectedOrder = state.orders.orderDetails[ownProps.orderId];
  const updates = _.get(state.orders.orderUpdates, ownProps.orderId, {});
  return {
    selectedOrder: selectedOrder,
    activeTab: state.orders.activeTab,
    isLoadingOrderDetails: state.loading.isLoadingOrderDetails,
    isEditing: _.size(updates) > 0,
  };
};

const mapDispatchToProps = {
  setSelectedTab,
  setTaxExempt,
  setTarp,
  setCreditCard,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(SelectedOrder);
