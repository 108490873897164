import _ from 'lodash';
import NP from 'number-precision';
import { ProductPrice } from '../models';

export const getSubtotal = (prices: ProductPrice[], quantity: number) => {
  if (_.isEmpty(prices)) { return null; }

  const { productPriceId, price } = _.find(prices, ({ startQuantity, endQuantity }) => {
    return quantity >= startQuantity && (endQuantity === null || quantity <= endQuantity);
  }) || {};

  if (!productPriceId || !price) {
    return null;
  }

  const subtotal = NP.times(price, quantity);

  return {
    productPriceId,
    price,
    displayPrice: `$${price.toFixed(2)}`,
    cartQuantity: quantity,
    subtotal,
    displaySubtotal: `$${subtotal.toFixed(2)}`,
  };
};
