import React from 'react';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { RootState } from '../Modules/reducers';
import { toggleCustomerView } from '../Modules/site/actions';

interface LocalProps {}

interface StateProps {
  isCustomerView: boolean;
}

interface DispatchProps {
  toggleCustomerView: typeof toggleCustomerView;
}

type Props = LocalProps & StateProps & DispatchProps;

const Header = ({ isCustomerView, toggleCustomerView }: Props) => (
  <nav className="d-flex align-items-center justify-content-between sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <div className="d-flex">
      <Link to="/" className="navbar-brand">SWH Pickup</Link>
      <button className="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle">
        <i className="fa fa-bars" />
      </button
      >
    </div>

    <div className="d-flex text-white">
      <div>
        <label className="d-flex align-items-center child-spacing-x-1 m-0"><span>Customer View</span> <Toggle checked={isCustomerView} onChange={toggleCustomerView} /></label>
      </div>
    </div>
  </nav>
);
const mapStateToProps = (state: RootState) => {
  return {
    isCustomerView: state.site.isCustomerView,
  };
};

const mapDispatchToProps = {
  toggleCustomerView,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(Header);
