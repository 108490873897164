import React, { ReactNode } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { ReactChildren } from '../models';

interface LocalProps {
  Header: ReactNode;
  defaultOpen?: boolean;
  children?: ReactChildren;
}

interface LocalState {
  isOpen: boolean;
}

class CollapsibleCard extends React.Component<LocalProps, LocalState> {
  constructor (props: LocalProps) {
    super(props);

    this.state = {
      isOpen: _.isNil(props.defaultOpen) ? false : !!props.defaultOpen,
    };
  }

  public toggleIsOpen = (): void => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  public setIsOpen = (isOpen: boolean): void => {
    this.setState({ isOpen: isOpen });
  };

  public render (): any {
    const { isOpen } = this.state;
    const { Header, children } = this.props;
    return (
      <Card>
        <CardHeader>
          <span className="no-outline" onClick={this.toggleIsOpen}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <i className={classnames({
                  'fa mr-1': true,
                  'fa-chevron-right': !isOpen,
                  'fa-chevron-down': isOpen,
                })}
                />
              </div>
              <div style={{ flex: 1 }}>
                {_.isString(Header) && <>{Header}</>}
                {(Header && !_.isString(Header)) &&
                (
                  <>
                    {Header}
                  </>
                )}
              </div>
            </div>
          </span>
        </CardHeader>

        <Collapse isOpen={isOpen}>
          <CardBody>
            {children}
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

export default CollapsibleCard;
