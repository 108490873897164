import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../Modules/reducers';

interface LocalProps {
  children: ReactNode;
}

interface StateProps {
  isCustomerView: boolean;
}

interface DispatchProps {
}

type Props = LocalProps & StateProps & DispatchProps;

const CustomerViewAutoHide = ({ isCustomerView, children }: Props) => {
  if (isCustomerView) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    isCustomerView: state.site.isCustomerView,
  };
};

const mapDispatchToProps = {
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(CustomerViewAutoHide);
