import React from 'react';
import toastr from 'toastr';
import _ from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { RootState } from '../../Modules/reducers';
import {
  OrderProductXref,
  OrderProductXrefUpdateDto,
  OrderUpdateDto,
  OrderWithProducts,
  ProductWithStats
} from '../../models';
import { markOrderPickedUp, setOrderValues, updateOrder } from '../../Modules/orders/actions';
import OrderEditRow from './OrderEditRow';
import { DebounceInput } from 'react-debounce-input';

interface LocalProps {
  orderDetails: OrderWithProducts;
}

interface LocalState {
}

interface StateProps {
  products: {[productId: number]: ProductWithStats};
  updates: Partial<OrderWithProducts>;
  isDirty: boolean;
  isSavingOrder: boolean;
}

interface DispatchProps {
  setOrderValues: typeof setOrderValues;
  markOrderPickedUp: typeof markOrderPickedUp;
  updateOrder: typeof updateOrder;
}

type Props = LocalProps & StateProps & DispatchProps;

class SelectedOrderEdit extends React.Component<Props, LocalState> {
  private saveOrder = (): void => this.saveOrderAnd();

  private saveOrderAnd = (onSuccess?: (updatedOrder: OrderWithProducts) => void) => {
    const { orderDetails, updates } = this.props;

    if (onSuccess && _.isEmpty(updates)) {
      onSuccess(orderDetails);
      return;
    }

    const mergedOrderDetails = {
      ...orderDetails,
      ...updates,
    };

    const invalidPrices = _.filter(mergedOrderDetails.products, p => !_.isNumber(p.price));

    if (_.size(invalidPrices) > 0) {
      const [first] = invalidPrices;
      toastr.error(`Double check the price for ${first.name}, it must be a valid decimal to save.`);
      return;
    }

    const dto: OrderUpdateDto = {
      orderId: orderDetails.orderId,
      name: mergedOrderDetails.name,
      email: mergedOrderDetails.email,
      phoneNumber: mergedOrderDetails.phoneNumber,
      workPhoneNumber: mergedOrderDetails.phoneNumber,
      comments: mergedOrderDetails.comments,
      products: _.map(mergedOrderDetails.products || [], (orderProductXref): OrderProductXrefUpdateDto => {
        return {
          productId: orderProductXref.productId,
          quantity: orderProductXref.quantity,
          price: orderProductXref.price as number,
          isStatic: !orderProductXref.isCalculated,
        };
      }),
    };

    this.props.updateOrder(dto, (order) => {
      if (onSuccess) {
        onSuccess(order);
      }
    });
  };

  private resetOrder = () => {
    const { orderDetails } = this.props;

    this.props.setOrderValues(orderDetails.orderId, null);
  };

  private setOrderValues = (values: Partial<OrderWithProducts>) => {
    const { orderDetails } = this.props;

    this.props.setOrderValues(orderDetails.orderId, values);
  };

  private setOrderProductValues = (index: number) => (values: Partial<OrderProductXref>) => {
    const { orderDetails, updates } = this.props;

    const mergedOrderDetails = {
      ...orderDetails,
      ...updates,
    };

    const updatedProducts = [...mergedOrderDetails.products];

    const currentProduct = updatedProducts[index];

    updatedProducts[index] = {
      ...currentProduct,
      ...values,
    };

    this.props.setOrderValues(orderDetails.orderId, {
      products: updatedProducts,
    });
  };

  private addProduct = () => {
    const { orderDetails, updates } = this.props;

    const mergedOrderDetails = {
      ...orderDetails,
      ...updates,
    };

    const updatedProducts = [...mergedOrderDetails.products];

    const maxPosition = _.maxBy(updatedProducts, p => p.position);

    const newProduct: OrderProductXref = {
      name: '',
      createdTimestamp: '',
      displayPrice: '',
      displayTax: '',
      displayTotal: '',
      displayUnitPrice: '',
      orderId: 0,
      position: (maxPosition?.position || 0) + 1,
      price: 0,
      productSize: '',
      productType: '',
      productTypeId: 0,
      quantity: 0,
      tax: 0,
      total: 0,
      unitPrice: 0,
      totalWithTaxAndFees: 0,
      displayTotalWithTaxAndFees: '',
      productId: 0,
      isCalculated: true,
      pickupTimestamp: null,
    };

    updatedProducts.push(newProduct);

    this.setOrderValues({
      products: updatedProducts,
    });
  };

  public render () {
    const { isDirty, orderDetails, updates, products } = this.props;

    const mergedOrderDetails = {
      ...orderDetails,
      ...updates,
    };

    const isAddingNewProduct = !!_.find(mergedOrderDetails.products, p => p.productId === 0);

    return (
      <>
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-between">
              <div className="child-spacing-x-1">
                <button className="btn btn-primary" onClick={this.saveOrder} disabled={!isDirty}><i className="fa fa-save" /></button>
                <button className="btn btn-primary" onClick={this.resetOrder} disabled={!isDirty}><i className="fa fa-history" /> Revert</button>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={4} lg={3}>
            <Card>
              <CardBody>
                <div className="form-group">
                  <label>Name:</label>
                  <DebounceInput debounceTimeout={400} className="form-control" placeholder="Name..." value={mergedOrderDetails.name} onChange={(e) => this.setOrderValues({ name: e.target.value })} />
                </div>

                <div className="form-group">
                  <label>Email:</label>
                  <DebounceInput debounceTimeout={400} className="form-control" placeholder="Email..." value={mergedOrderDetails.email} onChange={(e) => this.setOrderValues({ email: e.target.value })} />
                </div>

                <div className="form-group">
                  <label>Phone Number:</label>
                  <DebounceInput debounceTimeout={400} className="form-control" placeholder="Phone Number..." value={mergedOrderDetails.phoneNumber} onChange={(e) => this.setOrderValues({ phoneNumber: e.target.value })} />
                </div>

                <div className="form-group">
                  <label>Work Phone Number:</label>
                  <DebounceInput debounceTimeout={400} className="form-control" placeholder="Work Phone Number..." value={mergedOrderDetails.workPhoneNumber || ''} onChange={(e) => this.setOrderValues({ workPhoneNumber: e.target.value })} />
                </div>

                <div className="form-group">
                  <label>Comments:</label>
                  <DebounceInput debounceTimeout={400} element="textarea" className="form-control" placeholder="Comments..." value={mergedOrderDetails.comments || ''} onChange={(e) => this.setOrderValues({ comments: e.target.value })} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} lg={9} className="mt-3 mt-md-0">
            <div className="order-products">
              {
                _.map(mergedOrderDetails.products, (productData, index) => {
                  const { productId } = productData;

                  if (!productData && productId !== 0) {
                    return null;
                  }

                  const productWithStats = _.get(products, productId, null);

                  return (
                    <OrderEditRow
                      key={productId}
                      order={mergedOrderDetails}
                      originalOrder={orderDetails}
                      product={productWithStats}
                      orderProduct={productData}
                      setProductValues={this.setOrderProductValues(index)}
                    />
                  );
                })
              }
            </div>

            {!isAddingNewProduct && (
              <div className="mt-2">
                <button className="btn btn-primary" onClick={this.addProduct}>Add Product</button>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: LocalProps) => {
  return {
    products: state.products.products,
    updates: _.get(state.orders.orderUpdates, ownProps.orderDetails.orderId, {}),
    isDirty: _.has(state.orders.orderUpdates, ownProps.orderDetails.orderId),
    isSavingOrder: state.loading.isUpdatingOrder,
  };
};

const mapDispatchToProps = {
  setOrderValues,
  updateOrder,
  markOrderPickedUp,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(SelectedOrderEdit);
