import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { RootState } from '../../Modules/reducers';
import CustomerViewAutoHide from '../../SWH/CustomerViewAutoHide';

interface LocalProps {}

interface LocalState {
}

interface StateProps {
  numPickedUpOrders: number;
  numNonPickedUpOrders: number;
}

interface DispatchProps {
}

type Props = LocalProps & StateProps & DispatchProps;

class DashboardPage extends React.Component<Props, LocalState> {
  public render () {
    const { numPickedUpOrders, numNonPickedUpOrders } = this.props;

    const pickedUpKey = `Picked Up (${numPickedUpOrders})`;
    const notPickedUpKey = `Not Picked Up (${numNonPickedUpOrders})`;

    const data = [
      {
        name: 'Orders', [pickedUpKey]: numPickedUpOrders, [notPickedUpKey]: numNonPickedUpOrders,
      }
    ];

    return (
      <Row>
        <Col md={6}>
          <CustomerViewAutoHide>
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <span>Non-Picked Up vs. Picked Up Orders</span>
                  <span>({numNonPickedUpOrders}/{numPickedUpOrders})</span>
                </div>
              </div>
              <div className="car-body" style={{ height: 500 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={data}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Legend />
                    <Bar dataKey={pickedUpKey} fill="#8884d8" />
                    <Bar dataKey={notPickedUpKey} fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </CustomerViewAutoHide>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    numPickedUpOrders: _.filter(state.orders.orders, o => !!o.pickupTimestamp).length,
    numNonPickedUpOrders: _.filter(state.orders.orders, o => !o.pickupTimestamp).length,
  };
};

const mapDispatchToProps = {
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(DashboardPage);
