import React from 'react';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { connect } from 'react-redux';

import Header from './Header';
import Sidebar from './Sidebar';
import Routes from './Routes';
import { RootState } from '../Modules/reducers';
import appConfig from '../config';
import initScripts from '../assets/js/scripts';
import { getAllOrdersForCurrentSeason, getOrderDetailsById } from '../Modules/orders/actions';
import { getAllProductsWithStats } from '../Modules/products/actions';

interface LocalProps {}

interface StateProps {
  isLoading: boolean;
}

interface DispatchProps {
  getAllOrdersForCurrentSeason: typeof getAllOrdersForCurrentSeason;
  getAllProductsWithStats: typeof getAllProductsWithStats;
  getOrderDetailsById: typeof getOrderDetailsById;
}

type Props = LocalProps & StateProps & DispatchProps;

class App extends React.Component<Props> {
  private initialSignalrConnection = async () => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${appConfig.baseUrl}/Orders`)
      .build();

    await connect(connection);

    this.setupListeners(connection);

    async function connect (conn: HubConnection) {
      conn.start().catch(async () => {
        await sleep(5000);
        console.log('Reconnecting Socket');
        await connect(conn);
      });
    }

    connection.onclose(() => {
      connect(connection);
    });

    async function sleep (milliseconds: number) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
  };

  private setupListeners = (connection: HubConnection) => {
    connection.on('updateOrder', (orderId: number, userId: number) => {
      this.props.getOrderDetailsById(orderId);
    });
  };

  componentDidMount (): void {
    this.initialSignalrConnection();

    this.loadData();

    initScripts();
  }

  loadData = () => {
    this.props.getAllOrdersForCurrentSeason();
    this.props.getAllProductsWithStats();
  };

  public render () {
    const { isLoading } = this.props;

    return (
      <>
        <Header />

        <div id="layoutSidenav">
          <Sidebar />
          <div id="layoutSidenav_content">
            {isLoading && (
              <main className="p-3">
                <h2 className="text-center"><i className="fa fa-spin fa-refresh" /> Loading Products...</h2>
              </main>
            )}

            {!isLoading && (
              <main className="p-3">
                <Routes />
              </main>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.loading.isLoadingAllProductsWithStats || state.loading.isLoadingOrdersForSeason,
  };
};

const mapDispatchToProps = {
  getAllOrdersForCurrentSeason,
  getAllProductsWithStats,
  getOrderDetailsById,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(App);
