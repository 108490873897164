export const persistPageSize = (tableId, defaultPageSize) => {
  const postfix = 'default_page_size';
  const key = tableId ? `${tableId}_${postfix}` : `AW3_${postfix}`;
  return {
    onPageSizeChange: (pageSize) => {
      localStorage.setItem(key, pageSize);
    },
    defaultPageSize: localStorage.getItem(key) ? parseInt(localStorage.getItem(key), 10) : defaultPageSize || 10,
  };
};

const _genericAccessorFunction = (sortableProperty) => (data) => {
  return data[sortableProperty];
};

const _sortableCellFunction = (displayProperty) => ({ original }) => {
  return original[displayProperty];
};

export const displaySortable = (sortableProperty, displayProperty, id) => {
  return {
    id: id || sortableProperty,
    accessor: _genericAccessorFunction(sortableProperty),
    Cell: _sortableCellFunction(displayProperty),
  };
};
