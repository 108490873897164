import _ from 'lodash';
import { SuperAgent } from 'superagent';
import appConfig from '../../config';
import {
  Action,
  ApiAction,
  OrderFlagUpdateDto,
  OrderUpdateDto,
  OrderWithProducts,
  SelectedOrderTab
} from '../../models';
import { commonActions } from '../common/actions';

export const orderActions = {
  SET_SELECTED_TAB: 'SWH/orders/setSelectedTab',
  SELECT_ORDER: 'SWH/orders/selectOrder',
  SET_ORDER_VALUES: 'SWH/orders/setOrderValues',

  GET_ALL_ORDERS_FOR_CURRENT_SEASON_REQUEST: 'SWH/orders/GetAllOrdersForCurrentSeasonRequest',
  GET_ALL_ORDERS_FOR_CURRENT_SEASON_SUCCESS: 'SWH/orders/GetAllOrdersForCurrentSeasonSuccess',
  GET_ALL_ORDERS_FOR_CURRENT_SEASON_FAILURE: 'SWH/orders/GetAllOrdersForCurrentSeasonFailure',

  GET_ORDER_DETAILS_BY_ID_REQUEST: 'SWH/orders/GetOrderDetailsByIdRequest',
  GET_ORDER_DETAILS_BY_ID_SUCCESS: 'SWH/orders/GetOrderDetailsByIdSuccess',
  GET_ORDER_DETAILS_BY_ID_FAILURE: 'SWH/orders/GetOrderDetailsByIdFailure',

  MARK_ORDER_PICKED_UP_REQUEST: 'SWH/orders/MarkOrderPickedUpRequest',
  MARK_ORDER_PICKED_UP_SUCCESS: 'SWH/orders/MarkOrderPickedUpSuccess',
  MARK_ORDER_PICKED_UP_FAILURE: 'SWH/orders/MarkOrderPickedUpFailure',

  SET_TARP_REQUEST: 'SWH/orders/SetTarpRequest',
  SET_TARP_SUCCESS: 'SWH/orders/SetTarpSuccess',
  SET_TARP_FAILURE: 'SWH/orders/SetTarpFailure',

  SET_CREDIT_CARD_REQUEST: 'SWH/orders/SetCreditCardRequest',
  SET_CREDIT_CARD_SUCCESS: 'SWH/orders/SetCreditCardSuccess',
  SET_CREDIT_CARD_FAILURE: 'SWH/orders/SetCreditCardFailure',

  SET_TAX_EXEMPT_REQUEST: 'SWH/orders/SetTaxExemptRequest',
  SET_TAX_EXEMPT_SUCCESS: 'SWH/orders/SetTaxExemptSuccess',
  SET_TAX_EXEMPT_FAILURE: 'SWH/orders/SetTaxExemptFailure',

  MARK_ORDER_PRODUCTS_PICKED_UP_REQUEST: 'SWH/orders/MarkOrderProductsPickedUpRequest',
  MARK_ORDER_PRODUCTS_PICKED_UP_SUCCESS: 'SWH/orders/MarkOrderProductsPickedUpSuccess',
  MARK_ORDER_PRODUCTS_PICKED_UP_FAILURE: 'SWH/orders/MarkOrderProductsPickedUpFailure',

  UPDATE_ORDER_REQUEST: 'SWH/order/UpdateOrderRequest',
  UPDATE_ORDER_SUCCESS: 'SWH/order/UpdateOrderSuccess',
  UPDATE_ORDER_FAILURE: 'SWH/order/UpdateOrderFailure',
};

export const setSelectedTab = (activeTab: SelectedOrderTab): Action => {
  return {
    type: orderActions.SET_SELECTED_TAB,
    payload: {
      activeTab: activeTab,
    },
  };
};

export const selectOrder = (orderId: number | null): Action => {
  return {
    type: orderActions.SELECT_ORDER,
    payload: {
      orderId: orderId,
    },
  };
};

export const setOrderValues = (orderId: number, updates: Partial<OrderWithProducts> | null): Action => {
  return {
    type: orderActions.SET_ORDER_VALUES,
    payload: {
      orderId: orderId,
      updates: updates,
    },
  };
};

export const getAllOrdersForCurrentSeason = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/Orders/GetAllOrdersForCurrentSeason/`);
      },
      beforeRequest: (): Action => ({ type: orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_REQUEST }),
      success: (data: any): Action => ({ type: orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_SUCCESS, payload: { orders: data } }),
      failure: (response: any): Action => ({ type: orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_FAILURE, payload: { ...response } }),
    },
  };
};

export const getOrderDetailsById = (orderId: number): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/Orders/GetOrderDetailsById/`)
          .query({ orderId });
      },
      beforeRequest: (): Action => ({ type: orderActions.GET_ORDER_DETAILS_BY_ID_REQUEST }),
      success: (data: any): Action => ({ type: orderActions.GET_ORDER_DETAILS_BY_ID_SUCCESS, payload: { order: data } }),
      failure: (response: any): Action => ({ type: orderActions.GET_ORDER_DETAILS_BY_ID_FAILURE, payload: { ...response } }),
    },
  };
};

export const markOrderPickedUp = (orderId: number, pickedUp: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/Orders/MarkOrderPickedUp`)
          .query({ orderId, pickedUp });
      },
      beforeRequest: (): Action => ({ type: orderActions.MARK_ORDER_PICKED_UP_REQUEST }),
      success: (data: any): Action => ({ type: orderActions.MARK_ORDER_PICKED_UP_SUCCESS, payload: { order: data } }),
      failure: (response: any): Action => ({ type: orderActions.MARK_ORDER_PICKED_UP_FAILURE, payload: { ...response } }),
    },
  };
};

export const setTarp = (orderId: number, tarp: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/Orders/SetTarp`)
          .query({ orderId, tarp });
      },
      beforeRequest: (): Action => ({ type: orderActions.SET_TARP_REQUEST }),
      success: (data: any): Action => ({ type: orderActions.SET_TARP_SUCCESS, payload: { order: data } }),
      failure: (response: any): Action => ({ type: orderActions.SET_TARP_FAILURE, payload: { ...response } }),
    },
  };
};

export const setCreditCard = (orderId: number, creditCard: boolean): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/Orders/SetCreditCard`)
          .query({ orderId, creditCard });
      },
      beforeRequest: (): Action => ({ type: orderActions.SET_CREDIT_CARD_REQUEST }),
      success: (data: any): Action => ({ type: orderActions.SET_CREDIT_CARD_SUCCESS, payload: { order: data } }),
      failure: (response: any): Action => ({ type: orderActions.SET_CREDIT_CARD_FAILURE, payload: { ...response } }),
    },
  };
};

export const setTaxExempt = (orderId: number, taxExempt: boolean): ApiAction => {
  const flags: OrderFlagUpdateDto = { orderId: orderId, isTaxExempt: taxExempt };

  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/Orders/Update/Flags`)
          .send(flags);
      },
      beforeRequest: (): Action => ({ type: orderActions.SET_TAX_EXEMPT_REQUEST }),
      success: (data: any): Action => ({ type: orderActions.SET_TAX_EXEMPT_SUCCESS, payload: { order: data } }),
      failure: (response: any): Action => ({ type: orderActions.SET_TAX_EXEMPT_FAILURE, payload: { ...response } }),
    },
  };
};

export const markOrderProductsPickedUp = (orderId: number, productIds: number[], pickedUp: boolean, successCallback?: () => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/Orders/MarkOrderProductsPickedUp`)
          .query({ orderId, pickedUp })
          .send(productIds || []);
      },
      beforeRequest: (): Action => ({ type: orderActions.MARK_ORDER_PRODUCTS_PICKED_UP_REQUEST }),
      success: (data: any): Action[] => {
        if (_.isFunction(successCallback)) {
          successCallback();
        }
        return ([
          getOrderDetailsById(orderId),
          { type: orderActions.MARK_ORDER_PRODUCTS_PICKED_UP_SUCCESS, payload: { order: data } }
        ]);
      },
      failure: (response: any): Action => ({ type: orderActions.MARK_ORDER_PRODUCTS_PICKED_UP_FAILURE, payload: { ...response } }),
    },
  };
};

export const updateOrder = (orderUpdate: OrderUpdateDto, onSuccess?: (order: OrderWithProducts) => void): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.put(`${appConfig.baseUrl}/api/v1/Orders/Update`)
          .send(orderUpdate);
      },
      beforeRequest: (): Action => ({ type: orderActions.UPDATE_ORDER_REQUEST }),
      success: (data: any): Action => {
        if (onSuccess) {
          onSuccess(data);
        }
        return ({ type: orderActions.UPDATE_ORDER_SUCCESS, payload: { order: data, isNew: orderUpdate.orderId === 0 } });
      },
      failure: (response: any): Action => ({ type: orderActions.UPDATE_ORDER_FAILURE, payload: { ...response } }),
    },
  };
};
