import React from 'react';
import _ from 'lodash';
import ReactSelect, { components, OptionProps } from 'react-select';
import { connect } from 'react-redux';

import { RootState } from '../Modules/reducers';
import { ProductWithStats } from '../models';
import { getAllProductsWithStats } from '../Modules/products/actions';
import { getSortedProducts } from '../Modules/products/selectors';
import ProductStatusBadge from './ProductStatusBadge';

const ProductWithStatsOption = React.memo((props: OptionProps<any, any>) => {
  const { data } = props;
  const product = data as ProductWithStats;

  return (
    <components.Option {...props}>
      <div>{product.displayNameAndSize} <ProductStatusBadge label={product.displayProductStatus} productStatus={product.productStatus} /></div>
    </components.Option>
  );
});

interface LocalProps {
  value: ProductWithStats | null;
  disabled?: boolean;
  excludedProducts: {[productId: number]: any};
  onChange: (product: ProductWithStats | null) => void;
}

interface StateProps {
  products: ProductWithStats[];
  isLoading: boolean;
}

interface DispatchProps {
  getAllProductsWithStats: typeof getAllProductsWithStats;
}

type Props = LocalProps & StateProps & DispatchProps;
class ProductWithStatsSearch extends React.PureComponent<Props> {
  public componentDidMount (): void {
    const { products, isLoading } = this.props;
    if (_.isEmpty(products) && !isLoading) {
      this.props.getAllProductsWithStats();
    }
  }

  public render (): any {
    const { products, disabled, excludedProducts, value, onChange, isLoading } = this.props;

    const availableProducts = _.filter(products, p => !_.has(excludedProducts, p.productId));

    return (
      <ReactSelect
        isLoading={isLoading}
        isDisabled={disabled}
        getOptionLabel={(o: ProductWithStats) => o.displayNameAndSize}
        getOptionValue={(o: ProductWithStats) => o.productId as any}
        value={value as any}
        options={availableProducts}
        components={{ Option: ProductWithStatsOption }}
        onChange={onChange as (option: any) => any}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    products: getSortedProducts(state),
    isLoading: state.loading.isLoadingAllProductsWithStats,
  };
};

const mapDispatchToProps = {
  getAllProductsWithStats,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(ProductWithStatsSearch);
