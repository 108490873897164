import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import createStore from './Modules/store';
import App from './App/App';
import './assets/scss/styles.scss';
import AuthLoader from './AuthLoader';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config';

const history = createBrowserHistory();
const rootElement = document.getElementById('root');
const store = createStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Auth0Provider
        domain={config.auth.domain}
        audience={config.auth.audience}
        clientId={config.auth.clientId}
        redirectUri={config.auth.redirectUri}
      >
        <AuthLoader>
          <App />
        </AuthLoader>
      </Auth0Provider>
    </ConnectedRouter>
  </Provider>,
  rootElement);
