import React from 'react';
import _ from 'lodash';
import NP from 'number-precision';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import { OrderProductXref, OrderWithProducts, ProductWithStats } from '../../models';
import ProductWithStatsSearch from '../../SWH/ProductWithStatsSearch';
import { getSubtotal } from '../../helpers/productHelpers';
import ButtonGroup from '../../Components/ButtonGroup';
import CustomerViewAutoHide from '../../SWH/CustomerViewAutoHide';
import ProductStatusBadge from '../../SWH/ProductStatusBadge';

const modeOptions = [
  { label: 'Manual', value: false },
  { label: 'Calculated', value: true }
];

interface LocalProps {
  order: OrderWithProducts;
  originalOrder: OrderWithProducts;
  orderProduct: OrderProductXref;
  product: ProductWithStats | null;
  setProductValues: (values: Partial<OrderProductXref>) => void;
}

class OrderEditRow extends React.Component<LocalProps> {
  changeProduct = (productWithStats: ProductWithStats | null) => {
    if (productWithStats) {
      const orderProductXref: OrderProductXref = {
        productId: productWithStats.productId,
        name: productWithStats.name,
        orderId: 0,
        position: 0,
        price: 0,
        productSize: '',
        productType: '',
        productTypeId: productWithStats.productTypeId,
        quantity: 0,
        tax: 0,
        total: 0,
        totalWithTaxAndFees: 0,
        unitPrice: 0,
        displayPrice: '0',
        displayTax: '0',
        displayTotal: '0',
        displayUnitPrice: '0',
        displayTotalWithTaxAndFees: '0',
        createdTimestamp: '',
        pickupTimestamp: null,
      };

      this.props.setProductValues(orderProductXref);
    }
  };

  private setProductMode = (isCalculated: boolean) => {
    this.props.setProductValues({
      isCalculated: isCalculated,
    });
  };

  private setProductPrice = (e: any) => {
    let value = e.target.value;

    if (/^(?:\d*\.\d+|\d+)$/.test(value)) {
      value = parseFloat(value);
    }

    this.props.setProductValues({
      price: value,
    });
  };

  private setQuantity = (e: any) => {
    const value = e.target.value;

    if (!/^[0-9]+$/.test(value)) {
      return;
    }

    const parsed = parseInt(e.target.value, 10);

    if (isNaN(parsed)) {
      return;
    }

    const { product, orderProduct } = this.props;
    const { prices } = product || {};
    const { quantity, isCalculated } = orderProduct;

    const calculated = getSubtotal(prices || [], quantity);

    let additionalValues: Partial<OrderProductXref> = {};

    if (isCalculated && calculated) {
      additionalValues = {
        price: calculated.subtotal,
      };
    }

    this.props.setProductValues({
      quantity: parsed,
      ...additionalValues,
    });
  };

  private renderName = () => {
    const { product, order, originalOrder } = this.props;

    const keyedOrderProducts = _.keyBy(order.products, (p) => p.productId);

    const currentOrderProduct = product ? _.get(keyedOrderProducts, product.productId) : undefined;
    const originalOrderProduct = _.find(originalOrder.products, p => p.productId === product?.productId);

    const diff = currentOrderProduct && originalOrderProduct ? originalOrderProduct.quantity - currentOrderProduct.quantity
      : (currentOrderProduct?.quantity || 0) * -1;

    return (
      <div className="form-group">
        <label className="font-weight-bold">Name:</label>
        <ProductWithStatsSearch disabled={!!currentOrderProduct?.pickupTimestamp} excludedProducts={keyedOrderProducts} value={product} onChange={this.changeProduct} />

        {product && (
          <CustomerViewAutoHide>
            <div><ProductStatusBadge label={product.displayProductStatus} productStatus={product.productStatus} /></div>
            <div><strong>Current Stock:</strong> {product.calculatedStock}</div>
            {!!diff && (
              <>
                <div><strong>Change In Stock:</strong> {diff > 0 && '+'}{diff} {originalOrderProduct && <small>(Originally Ordered: {originalOrderProduct.quantity})</small>}</div>
                <div><strong>Updated Stock:</strong> {(product.calculatedStock || 0) + diff}</div>
              </>
            )}
          </CustomerViewAutoHide>
        )}
      </div>
    );
  };

  private renderQuantity = () => {
    const { orderProduct } = this.props;
    const { quantity } = orderProduct;

    return (
      <div className="form-group">
        <label className="font-weight-bold">Quantity:</label>
        <input className="form-control" disabled={!!orderProduct?.pickupTimestamp} onChange={this.setQuantity} value={quantity !== null ? quantity : ''} />
      </div>
    );
  };

  private renderUnitPrice = () => {
    const { product, orderProduct } = this.props;
    const { productId } = orderProduct;
    const { prices } = product || {};
    const { quantity, price, isCalculated } = orderProduct;

    const calculated = getSubtotal(prices || [], quantity);
    const calculatedUnitPrice = calculated ? calculated.price : 0;

    const unitPrice = (_.isNumber(quantity) && _.isNumber(price) && quantity > 0) ? NP.divide(price, quantity) : 0;

    const displayUnitPrice = isCalculated ? calculatedUnitPrice : unitPrice;

    return (
      <div className="form-group">
        <label className="font-weight-bold">Unit Price:</label>
        <div data-tip data-for={`prices_${productId}`}>{_.isNumber(displayUnitPrice) ? displayUnitPrice.toFixed(2) : displayUnitPrice}</div>
        <ReactTooltip id={`prices_${productId}`} place="bottom" type="info" effect="float">
          {
            _.map(prices, ({ productPriceId, price: productPrice, startQuantity, endQuantity }) => {
              return (
                <div key={productPriceId}>${productPrice.toFixed(2)}: {startQuantity}{endQuantity ? ` - ${endQuantity}` : '+'}</div>
              );
            })
          }
        </ReactTooltip>
      </div>
    );
  };

  private renderPrice = () => {
    const { product, orderProduct } = this.props;
    const { prices } = product || {};
    const { isCalculated, price, quantity } = orderProduct;

    const calculated = getSubtotal(prices || [], quantity);
    const calculatedPrice = calculated ? calculated.subtotal : 0;

    const ProductModeToggle = (
      <ButtonGroup
        value={!!isCalculated}
        baseClassName="btn btn-sm"
        disabled={!!orderProduct?.pickupTimestamp}
        primaryClassName="btn-primary"
        defaultClassName="btn-light"
        onClick={this.setProductMode}
        buttonProps={{ tabIndex: -1 }}
        options={modeOptions}
      />
    );

    if (!isCalculated) {
      return (
        <div>
          <div className="form-group">
            <label className="font-weight-bold">Price:</label>
            <input disabled={!!orderProduct?.pickupTimestamp} className="form-control" onChange={this.setProductPrice} value={price} />
          </div>

          <div>
            {ProductModeToggle}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="form-group">
          <label className="font-weight-bold">Price:</label>
          <div>{_.isNumber(calculatedPrice) ? calculatedPrice.toFixed(2) : calculatedPrice}</div>
        </div>

        <div>
          {ProductModeToggle}
        </div>
      </div>
    );
  };

  public render () {
    const { orderProduct } = this.props;

    const id = `order-edit-row_${orderProduct.productId || 0}`;
    const isPickedUp = !!orderProduct.pickupTimestamp;

    return (
      <>
        <div className="order-product-row row" data-tip data-for={id}>
          <div className="col-md-4">
            {this.renderName()}
          </div>
          <div className="col-md-3">
            {this.renderQuantity()}
          </div>
          <div className="col-md-2">
            {this.renderUnitPrice()}
          </div>
          <div className="col-md-2">
            {this.renderPrice()}
          </div>

          {isPickedUp && (
            <ReactTooltip id={id} place="bottom" type="info" effect="float" className="bg-danger">
              Product has been marked as picked up, no changes are allowed.
            </ReactTooltip>
          )}
        </div>
      </>
    );
  }
}

export default OrderEditRow;
