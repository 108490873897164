import React from 'react';
import { ProductStatus } from '../models';

interface LocalProps {
  productStatus: ProductStatus | undefined | null;
  label: string;
}

const ProductStatusBadge = ({ productStatus, label }: LocalProps) => {
  switch (productStatus) {
    case ProductStatus.Active:
      return <small className="badge badge-info">{label}</small>;
    case ProductStatus.SoldOut:
      return <small className="badge badge-warning">{label}</small>;
    case ProductStatus.Deleted:
    case ProductStatus.Inactive:
      return <small className="badge badge-danger">{label}</small>;
    default:
      return <small className="badge badge-danger">{label}</small>;
  }
};

export default React.memo(ProductStatusBadge);
