import { Action as ReduxAction } from 'redux';
import { SuperAgent } from 'superagent';

export type ReactChildren = React.ReactNode | React.ReactNode[];

export interface Action extends ReduxAction {
    payload?: any;
    meta?: {
        file: any;
    };
}

export interface EnumOption<T> {
    label: string;
    value: T;
}

export interface ApiPayload {
    getRequest: (superagent: SuperAgent<any>) => any;
    beforeRequest: () => Action;
    success: (data: any, response: Response) => Action | Action[];
    failure: (response: ApiError) => Action;
}

export interface ApiAction extends Action {
    payload: ApiPayload;
}

export interface ApiError {
    validationError: boolean;
    message: string | null;
    error: any;
    response: Response;
}

export interface UserModel {
    userId: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

export interface LoginUserResponse{
    token: string;
}

export enum OrderStatus {
    Unsubmitted = 0,
    Submitted = 1,
    TreesAreIn = 2,
    CustomerPickedUp = 3,
}

export interface Order {
    orderId: number;
    createdTimestamp: string;
    orderStatus: OrderStatus;
    name: string;
    email: string;
    phoneNumber: string;
    workPhoneNumber: string | null;
    comments: string | null;
    pickupTimestamp: string | null;
    isTaxExempt: boolean;
    productStockProcessId: string | null;
    productStockProcessedTimestamp: string | null;
    displayPickupTimestamp: string;
    displayCreatedTimestamp: string;
    displayCreatedTimestampDate: string;
    numItems: number;
    displayNumItems: string;
    totalPrice: number;
    totalTax: number;
    totalWithTax: number;
    displayTotalPrice: string;
    displayTotalTax: string;
    displayTotalWithTax: string;
    displayTotalQuantity: string;
    seasonPurchased: number;
    creditCard: boolean;
    creditCardFee: number;
    baseCreditCardFee: number;
    tarp: boolean;
    tarpFee: number;
    totalFees: number;
    displayTotalFees: string;
}

export interface OrderProductXref {
    productId: number;
    name: string;
    orderId: number;
    quantity: number;
    productSize: string;
    productTypeId: number;
    productType: string;
    createdTimestamp: string;
    pickupTimestamp: string | null;
    price: number | string;
    displayPrice: string;
    unitPrice: number;
    displayUnitPrice: string;
    tax: number;
    displayTax: string;
    total: number;
    displayTotal: string;
    totalWithTaxAndFees: number;
    displayTotalWithTaxAndFees: string;
    position: number;

    numSold?: number;
    displayNumSold?: string;
    currentStock?: number;
    displayCurrentStock?: string;
    calculatedStock?: number;
    displayCalculatedStock?: string;
    productRevenue?: number;
    displayProductRevenue?: string;

    isCalculated?: boolean;
}

export interface OrderWithProducts extends Order {
    products: OrderProductXref[];
}

export enum ProductStatus {
    Inactive = 1,
    Active = 2,
    SoldOut = 3,
    Deleted = 4,
    SpecialOrder = 5,
    CallForAvailability = 6,
}

export interface ProductPrice {
    productPriceId: number;
    productId: number;
    createdTimestamp: string;
    startQuantity: number;
    endQuantity: number | null;
    price: number;
}
export interface ProductWithStats {
    productId: number;
    productTypeId: number;
    productType: string;
    productStatus: ProductStatus;
    displayProductStatus: string;
    name: string;
    displayNameAndSize: string;
    description: string;
    species: string | null;
    productSize: string | null;
    zones: string | null;
    productImage: string | null;
    croppedProductImage: string | null;
    productImageUploaded: boolean;
    productImageUser: string | null;
    productImageUrl: string | null;
    productImageCropped: boolean;
    prices: ProductPrice[];
    fullSizeImageUrl: string | null;
    thumbnailImageUrl: string | null;
    isDougs: boolean;
    isActive: boolean;
    isSpecialOrder: boolean;
    isActiveOrSpecialOrder: boolean;
    isSoldOut: boolean;
    isCallForAvailability: boolean;
    hasImage: boolean;

    numSold: number;
    displayNumSold: string;
    currentStock: number;
    displayCurrentStock: string;
    calculatedStock: number;
    displayCalculatedStock: string;
    productRevenue: number;
    displayProductRevenue: string;
}

export interface OrderUpdateDto {
    orderId: number;
    name: string;
    email: string;
    phoneNumber: string;
    workPhoneNumber: string | null;
    comments: string | null;
    products: OrderProductXrefUpdateDto[];
}

export interface OrderProductXrefUpdateDto {
    productId: number;
    quantity: number;
    price: number;
    isStatic: boolean;
}

export enum SelectedOrderTab {
  Edit = 'edit',
  Pickup = 'pickup',
}

export interface OrderFlagUpdateDto {
    orderId: number;
    isTaxExempt: boolean;
}
