import { Action, ApiAction } from '../../models';
import { commonActions } from '../common/actions';
import { SuperAgent } from 'superagent';
import appConfig from '../../config';

export const siteActions = {
  TOGGLE_CUSTOMER_VIEW: 'SWH/site/ToggleCustomerView',

  LOGIN_USER_REQUEST: 'SWH/site/LoginUserRequest',
  LOGIN_USER_SUCCESS: 'SWH/site/LoginUserSuccess',
  LOGIN_USER_FAILURE: 'SWH/site/LoginUserFailure',
};

export const toggleCustomerView = (): Action => {
  return {
    type: siteActions.TOGGLE_CUSTOMER_VIEW,
  };
};

export const loginUser = (usernameOrEmail: string, password: string): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.post(`${appConfig.baseUrl}/api/v1/Accounts/Login`)
          .send({ usernameOrEmail, password });
      },
      beforeRequest: (): Action => ({ type: siteActions.LOGIN_USER_REQUEST }),
      success: (data: any): Action => ({ type: siteActions.LOGIN_USER_SUCCESS, payload: data }),
      failure: (response: any): Action => ({ type: siteActions.LOGIN_USER_FAILURE, payload: { ...response } }),
    },
  };
};
