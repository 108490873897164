import { Action } from '../../models';
import { siteActions } from './actions';

export interface SiteState {
  token: string | null;
  isCustomerView: boolean;
}

const initState: SiteState = {
  token: null,
  isCustomerView: false,
};

export default (state = initState, action: Action): SiteState => {
  switch (action.type) {
    case siteActions.TOGGLE_CUSTOMER_VIEW: {
      return {
        ...state,
        isCustomerView: !state.isCustomerView,
      };
    }
    case siteActions.LOGIN_USER_SUCCESS: {
      const token = action.payload as string;

      return {
        ...state,
        token: token,
      };
    }
    default: {
      return state;
    }
  }
};
