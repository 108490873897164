import { CombinedState, combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import orderReducer, { OrderState } from './orders/reducer';
import productsReducer, { ProductsState } from './products/reducer';
import siteReducer, { SiteState } from './site/reducer';
import loadingReducer, { LoadingState } from './loading/reducer';

export interface RootState {
  loading: LoadingState;
  router: RouterState;
  orders: OrderState;
  products: ProductsState;
  site: SiteState;
}

export default (history: any): Reducer<CombinedState<RootState>> => combineReducers<RootState>({
  router: connectRouter(history),
  loading: loadingReducer,
  orders: orderReducer,
  products: productsReducer,
  site: siteReducer,
});
