import toastr from 'toastr';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { Action, ApiError } from '../models';

const Resolutions = {
  resolved: 1,
  unresolved: 2,
};

const errorHandlers = [
  (error: ApiError) => {
    if (error.validationError && error.message) {
      toastr.warning(error.message);

      return Resolutions.resolved;
    }

    return Resolutions.unresolved;
  },
  ({ error }: { error?: any} = {}) => {
    if (error.crossDomain) {
      toastr.error('The backend service is unavailable. Please try again later or reach out to your administrator.');

      return Resolutions.resolved;
    }

    return Resolutions.unresolved;
  }
];

const failureToastr = () => (next: Dispatch) => async (action: Action) => {
  if (!_.endsWith(_.toLower(action.type), 'failure')) {
    return next(action);
  }

  let resolved = false;
  for (const handler of errorHandlers) {
    const resolution = handler(action.payload);

    if (resolution === Resolutions.resolved) {
      resolved = true;
      break;
    }
  }

  if (!resolved) {
    toastr.error('An unknown error has occurred. Please refresh the page or try the action again.');
  }

  next(action);
};

export default failureToastr;
