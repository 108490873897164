import _ from 'lodash';
import numeral from 'numeral';
// @ts-ignore
import pdfMake from 'pdfmake/build/pdfmake';
// @ts-ignore
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { OrderProductXref, OrderWithProducts } from '../models';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class OrderPdfGenerator {
  static generatePdf (order: OrderWithProducts) {
    const currentdate = new Date();
    const displayGeneratedDate = `${currentdate.getMonth() + 1}/${currentdate.getDate()}/${currentdate.getFullYear()} ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`;

    pdfMake.createPdf({
      content: OrderPdfGenerator.getContent(order),
      footer: {
        columns: [
          { text: `Order: ${order.orderId}`, style: 'marginLeft20' },
          { text: displayGeneratedDate, alignment: 'right', style: 'marginRight20' }
        ],
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: 'center',
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        label: {
          fontSize: 12,
          bold: true,
        },
        table: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        marginTop5: {
          margin: [0, 5, 0, 0],
        },
        marginTop10: {
          margin: [0, 100, 0, 0],
        },
        marginLeft10: {
          margin: [10, 0, 0, 0],
        },
        marginRight10: {
          margin: [0, 0, 10, 0],
        },
        marginLeft20: {
          margin: [20, 0, 0, 0],
        },
        marginRight20: {
          margin: [0, 0, 20, 0],
        },
      },
      defaultStyle: {
        columnGap: 20,
      },
    }).download(OrderPdfGenerator.getFilename(order));
  }

  static getFilename ({ orderId, name }: OrderWithProducts) {
    return `${new Date().toJSON().slice(0, 10)}_${name}_${orderId}.pdf`;
  }

  static getContent (order: OrderWithProducts) {
    return [
      OrderPdfGenerator.getCustomerFacingSection(order),
      OrderPdfGenerator.getEmployeeFacingSection(order)
    ];
  }

  static getCalculations (order: OrderWithProducts) {
    const { baseCreditCardFee, tarpFee, products } = order;

    const totalPrice = _.sumBy(products, p => p.price as number);
    const displayTotalPrice = numeral(totalPrice).format('$0,0.00');

    const totalTax = _.sumBy(products, p => p.tax);
    const displayTotalTax = numeral(totalTax).format('$0,0.00');

    const totalWithTax = _.sumBy(products, p => p.total);
    const displayTotalWithTax = numeral(totalWithTax).format('$0,0.00');

    const totalCreditCardFee = order.creditCardFee ? order.isTaxExempt ? totalPrice * baseCreditCardFee : totalWithTax * baseCreditCardFee : 0;
    const totalFees = totalCreditCardFee + tarpFee;

    const totalQuantity = _.sumBy(products, p => p.quantity);
    const displayTotalQuantity = numeral(totalQuantity).format('0,0');

    const total = order.isTaxExempt ? totalPrice + totalFees : totalWithTax + totalFees;
    const displayTotal = numeral(total).format('$0,0.00');

    return {
      totalPrice,
      displayTotalPrice,
      totalTax,
      displayTotalTax,
      totalWithTax,
      displayTotalWithTax,
      totalCreditCardFee,
      tarpFee,
      totalFees,
      totalQuantity,
      displayTotalQuantity,
      total,
      displayTotal,
    };
  }

  static getCustomerFacingSection (order: OrderWithProducts) {
    const { orderId, name, email, phoneNumber, workPhoneNumber, isTaxExempt, displayCreatedTimestampDate, comments } = order;

    const { displayTotalPrice, displayTotalTax, totalFees, displayTotal, displayTotalQuantity } = OrderPdfGenerator.getCalculations(order);

    return [
      { text: 'Sustainable Wildlife Habitats', style: 'header' },
      {
        alignment: 'center',
        columns: [
          _.compact([
            { text: 'Order Id', style: ['label'] },
            orderId,

            { text: 'Name', style: ['label', 'marginTop5'] },
            name,

            { text: 'Phone Number', style: ['label', 'marginTop5'] },
            phoneNumber,

            workPhoneNumber ? { text: 'Work Phone Number', style: ['label', 'marginTop5'] } : null,
            workPhoneNumber
          ]),
          [
            { text: 'Email', style: ['label'] },
            email,

            { text: 'Order Placed On', style: ['label', 'marginTop5'] },
            displayCreatedTimestampDate
          ],
          [
            !isTaxExempt ? { text: 'Sub-Total', style: ['label', 'marginTop5'] } : null,
            !isTaxExempt ? displayTotalPrice : null,

            !isTaxExempt ? { text: 'Total Tax', style: ['label', 'marginTop5'] } : null,
            !isTaxExempt ? displayTotalTax : null,

            totalFees ? { text: 'Total Fees', style: ['label', 'marginTop5'] } : null,
            totalFees ? numeral(totalFees).format('$0,0.00') : null,

            { text: 'Total', style: ['label', 'marginTop5'] },
            displayTotal
          ]
        ],
      },
      { text: `Comments: ${comments?.trim() || 'NONE'}`, alignment: 'center' },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: _.compact(['*', 'auto', 'auto', 'auto', 'auto', !order.isTaxExempt ? 'auto' : null, !order.isTaxExempt ? 'auto' : null, 'auto']),
          body: OrderPdfGenerator.getProductsTable(order).concat([_.compact([
            { text: '' },
            { text: '' },
            { text: '' },
            { text: displayTotalQuantity, style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
            !order.isTaxExempt ? { text: '' } : null,
            !order.isTaxExempt ? { text: displayTotalTax, style: 'tableHeader' } : null,
            { text: displayTotal, style: 'tableHeader' }
          ])] as any),
        },
        pageBreak: 'after',
      }
    ];
  }

  static getEmployeeFacingSection (order: OrderWithProducts) {
    const { name, products } = order;

    return [
      {
        alignment: 'center',
        columns: [
          _.compact([
            { text: 'Name', style: ['label', 'marginTop5'] },
            name
          ])
        ],
      },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: [10, 'auto', 'auto', 'auto', 'auto'],
          body: [[
            { text: '', style: 'tableHeader' },
            { text: 'Type', style: 'tableHeader' },
            { text: 'Name', style: 'tableHeader' },
            { text: 'Size', style: 'tableHeader' },
            { text: 'Quantity', style: 'tableHeader' }
          ]].concat(OrderPdfGenerator.getPackingSlipTable(products) as any),
        },
      }
    ];
  }

static getProductsTable = (order: OrderWithProducts) => {
  return [_.compact([
    { text: 'Name', style: 'tableHeader' },
    { text: 'Size', style: 'tableHeader' },
    { text: 'Product Type', style: 'tableHeader' },
    { text: 'Quantity', style: 'tableHeader' },
    { text: 'Unit', style: 'tableHeader' },
    !order.isTaxExempt ? { text: 'Price', style: 'tableHeader' } : null,
    !order.isTaxExempt ? { text: 'Tax', style: 'tableHeader' } : null,
    { text: 'Total', style: 'tableHeader' }
  ])].concat(_.map(order.products, (product: OrderProductXref) => {
    const { name, productSize, productType, quantity, displayUnitPrice, displayTax, displayTotal, displayPrice } = product;
    return _.filter([
      name,
      productSize,
      productType,
      quantity,
      displayUnitPrice,
      !order.isTaxExempt ? displayPrice : undefined,
      !order.isTaxExempt ? displayTax : undefined,
      !order.isTaxExempt ? displayTotal : displayPrice
    ], d => !_.isUndefined(d));
  }) as any).concat(OrderPdfGenerator.getFeeProductRows(order));
};

static getFeeProductRows (order: OrderWithProducts): any[] {
  const { tarpFee, totalCreditCardFee } = OrderPdfGenerator.getCalculations(order);

  const fees: any[] = [];

  if (tarpFee) {
    fees.push(_.filter([
      'TARP FEE',
      '',
      '',
      '',
      '',
      !order.isTaxExempt ? '' : undefined,
      !order.isTaxExempt ? '' : undefined,
      numeral(tarpFee).format('$0,0.00')
    ], d => !_.isUndefined(d)));
  }

  if (totalCreditCardFee) {
    fees.push(_.filter([
      'CREDIT CARD FEE',
      '',
      '',
      '',
      '',
      !order.isTaxExempt ? '' : undefined,
      !order.isTaxExempt ? '' : undefined,
      numeral(totalCreditCardFee).format('$0,0.00')
    ], d => !_.isUndefined(d)));
  }

  return fees;
}

static getPackingSlipTable = (products: OrderProductXref[]) => {
  return _.map(products, (product) => {
    const { productType, name, productSize, quantity } = product;
    return [
      '',
      productType,
      name,
      productSize,
      quantity
    ];
  });
};
}

export default OrderPdfGenerator;
