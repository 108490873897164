import _ from 'lodash';
import { Action, Order, OrderStatus, OrderWithProducts, SelectedOrderTab } from '../../models';
import { orderActions } from './actions';

export interface OrderState {
  selectedOrderId: number | null;
  orders: { [orderId: number]: Order };
  orderDetails: { [orderId: number]: OrderWithProducts };
  orderUpdates: { [orderId: number]: Partial<OrderWithProducts> };
  activeTab: SelectedOrderTab;
}

const initState: OrderState = {
  selectedOrderId: null,
  orders: {},
  orderDetails: { 0: getNewOrder() },
  orderUpdates: {},
  activeTab: SelectedOrderTab.Edit,
};

export default (state = initState, action: Action): OrderState => {
  switch (action.type) {
    case orderActions.SET_SELECTED_TAB: {
      const { activeTab }: {activeTab: SelectedOrderTab} = action.payload;

      return {
        ...state,
        activeTab: activeTab,
      };
    }
    case orderActions.SELECT_ORDER: {
      const { orderId }: { orderId: number | null } = action.payload;

      return {
        ...state,
        selectedOrderId: orderId,
      };
    }
    case orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_SUCCESS: {
      const { orders }: { orders: Order[] } = action.payload;

      return {
        ...state,
        orders: _.keyBy(orders, o => o.orderId),
      };
    }
    case orderActions.SET_CREDIT_CARD_SUCCESS:
    case orderActions.SET_TAX_EXEMPT_SUCCESS:
    case orderActions.SET_TARP_SUCCESS:
    case orderActions.GET_ORDER_DETAILS_BY_ID_SUCCESS: {
      const { order: orderDetails } = action.payload as { order: OrderWithProducts };
      const { products, ...updatedOrder } = orderDetails;

      return {
        ...state,
        orders: {
          ...state.orders,
          [updatedOrder.orderId]: updatedOrder,
        },
        orderDetails: {
          [orderDetails.orderId]: orderDetails,
        },
      };
    }
    case orderActions.UPDATE_ORDER_SUCCESS: {
      const { order: orderDetails, isNew } = action.payload as { order: OrderWithProducts; isNew: boolean };

      const { products, ...updatedOrder } = orderDetails;

      return {
        ...state,
        orders: {
          ...state.orders,
          [updatedOrder.orderId]: updatedOrder,
        },
        selectedOrderId: isNew ? orderDetails.orderId : state.selectedOrderId,
        orderDetails: {
          [orderDetails.orderId]: orderDetails,
        },
        orderUpdates: _.omit(state.orderUpdates, orderDetails.orderId),
      };
    }
    case orderActions.SET_ORDER_VALUES: {
      const { orderId, updates }: { orderId: number; updates: Partial<OrderWithProducts> | null } = action.payload;

      const orderUpdates = {
        [orderId]: {
          ..._.get(state.orderUpdates, orderId, {}),
          ...updates || {},
        },
      };

      if (updates == null) {
        delete orderUpdates[orderId];
      }

      return {
        ...state,
        orderUpdates: orderUpdates,
      };
    }
    default: {
      return state;
    }
  }
};

function getNewOrder (): OrderWithProducts {
  return {
    comments: '',
    createdTimestamp: '',
    displayCreatedTimestamp: '',
    displayCreatedTimestampDate: '',
    displayNumItems: '',
    displayPickupTimestamp: '',
    displayTotalPrice: '',
    displayTotalQuantity: '',
    displayTotalTax: '',
    displayTotalWithTax: '',
    email: '',
    isTaxExempt: false,
    name: '',
    numItems: 0,
    orderId: 0,
    orderStatus: OrderStatus.Unsubmitted,
    phoneNumber: '',
    pickupTimestamp: null,
    productStockProcessId: null,
    productStockProcessedTimestamp: null,
    products: [],
    seasonPurchased: 0,
    totalPrice: 0,
    totalTax: 0,
    totalWithTax: 0,
    workPhoneNumber: null,
    creditCard: false,
    baseCreditCardFee: 0,
    creditCardFee: 0,
    tarp: false,
    tarpFee: 0,
    totalFees: 0,
    displayTotalFees: '',
  };
}
