export interface SiteConfig {
  baseUrl: string;
  auth: {
    domain: string;
    audience: string;
    clientId: string;
    redirectUri: string;
  }
}

const config: SiteConfig = {
  baseUrl: 'https://sustainablewildlifehabitats.com/',
  auth: {
    domain: 'sustainablewildlifehabitats.us.auth0.com',
    audience: 'https://sustainablewildlifehabitats.com',
    clientId: 'PHQqZzOsLNnwiX60XOLqc15ADuOeRMgp',
    redirectUri: window.location.origin,
  },
};

export default config;
