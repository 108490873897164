import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { DebounceInput } from 'react-debounce-input';

import { RootState } from '../../Modules/reducers';
import { getOrderDetailsById, markOrderPickedUp, selectOrder } from '../../Modules/orders/actions';
import CollapsibleCard from '../../Components/CollapsibleCard';
import { Order } from '../../models';
import { displaySortable } from '../../helpers/tableHelper';
import SelectedOrder from './SelectedOrder';
import ButtonGroup from '../../Components/ButtonGroup';
import { getOrdersIndex, getSortedOrders } from '../../Modules/orders/selectors';
import Fuse from 'fuse.js';

interface LocalProps {}

interface LocalState {
  filterText: string;
}

interface StateProps {
  selectedOrderId: number | null;
  isCustomerView: boolean;
  orders: Order[];
  ordersIndex: Fuse<Order, { keys: string[] }>;
}

interface DispatchProps {
  getOrderDetailsById: typeof getOrderDetailsById;
  markOrderPickedUp: typeof markOrderPickedUp;
  selectOrder: typeof selectOrder;
}

type Props = LocalProps & StateProps & DispatchProps;

export const yesNoOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

class PickupPage extends React.Component<Props, LocalState> {
  private ordersCardRef: CollapsibleCard | null = null;

  constructor (props: Props) {
    super(props);

    this.state = {
      filterText: '',
    };
  }

  private addOrder = () => {
    if (this.ordersCardRef) {
      this.ordersCardRef.setIsOpen(false);
    }

    this.props.selectOrder(0);
  };

  private toggleSelected = (orderId: number) => {
    const { selectedOrderId: currentlySelectedOrderId } = this.props;

    const selectedOrderId = currentlySelectedOrderId !== orderId ? orderId : null;

    if (selectedOrderId) {
      this.props.getOrderDetailsById(selectedOrderId);
      if (this.ordersCardRef) {
        this.ordersCardRef.setIsOpen(false);
      }
    }

    this.props.selectOrder(selectedOrderId);
  };

  private setOrdersCardRef = (c: CollapsibleCard) => {
    this.ordersCardRef = c;
  };

  private renderMarkPickedUp = ({ original }: { original: Order }) => {
    return (
      <ButtonGroup
        stopPropagation
        value={!!original.pickupTimestamp}
        baseClassName="btn btn-sm"
        primaryClassName={original.pickupTimestamp ? 'btn-success' : 'btn-danger'}
        defaultClassName="btn-light"
        onClick={(pickedUp: boolean) => this.props.markOrderPickedUp(original.orderId, pickedUp)}
        buttonProps={{ tabIndex: -1 }}
        options={yesNoOptions}
      />
    );
  };

  private setFilterText = (e: any) => {
    this.setState({ filterText: e.target.value });
  };

  private getFilteredOrders = (): Order[] => {
    const { filterText } = this.state;
    const { ordersIndex, orders } = this.props;

    if (!filterText.trim()) {
      return orders;
    }

    return ordersIndex.search(filterText).map(r => r.item);
  };

  public render () {
    const { filterText } = this.state;
    const { selectedOrderId, isCustomerView } = this.props;

    const orders = this.getFilteredOrders();

    const options: any = {
      className: '-striped -hover',
      data: orders,
      minRows: 0,
      columns: _.compact([
        { Header: 'Order Id', accessor: 'orderId', width: 75 },
        { Header: 'Order Date', ...displaySortable('createdTimestamp', 'displayCreatedTimestamp'), width: 110 },
        { Header: 'Customer Name', accessor: 'name' },
        !isCustomerView ? { Header: 'Customer Email', accessor: 'email' } : null,
        !isCustomerView ? { Header: 'Customer Phone Number', accessor: 'phoneNumber' } : null,
        { Header: '# Items', ...displaySortable('numItems', 'displayNumItems'), width: 65 },
        !isCustomerView ? { Header: 'Order Total', ...displaySortable('totalPrice', 'displayTotalPrice'), width: 90 } : null,
        { Header: 'Picked Up', id: 'pickedUp', Cell: this.renderMarkPickedUp, className: 'text-center', width: 105 }
      ]),
      getTrProps: (state: any, { original }: { original: Order}) => {
        const isSelected = selectedOrderId === original.orderId;

        return {
          onClick: () => {
            this.toggleSelected(original.orderId);
          },
          className: classnames({
            'selected-row': isSelected,
            'picked-up-order': !!original.pickupTimestamp,
          }),
        };
      },
    };

    return (
      <Row>
        <Col md={12}>
          <CollapsibleCard ref={this.setOrdersCardRef} Header={`Search Orders (${_.size(orders)})`} defaultOpen>
            <div className="mb-2">
              <div className="d-flex child-spacing-x-2">
                <div style={{ flex: 1 }}>
                  <DebounceInput debounceTimeout={400} className="form-control" placeholder="Search for Order..." value={filterText} onChange={this.setFilterText} />
                </div>
                <div>
                  <button className="btn btn-primary" onClick={this.addOrder}>Add Order</button>
                </div>
              </div>
            </div>
            <div>
              <ReactTable {...options} />
            </div>
          </CollapsibleCard>

          {!_.isNil(selectedOrderId) && (
            <div className="mt-2">
              <CollapsibleCard Header="Selected Order" defaultOpen>
                <SelectedOrder orderId={selectedOrderId} />
              </CollapsibleCard>
            </div>)}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedOrderId: state.orders.selectedOrderId,
    isCustomerView: state.site.isCustomerView,
    orders: getSortedOrders(state),
    ordersIndex: getOrdersIndex(state),
  };
};

const mapDispatchToProps = {
  selectOrder,
  getOrderDetailsById,
  markOrderPickedUp,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(PickupPage);
