import _ from 'lodash';

export const preventDefaultAnd = (callback?: Function) => (e: any) => {
  e.preventDefault();
  if (_.isFunction(callback)) {
    callback();
  }
};

export const stopPropagationAnd = (callback?: Function) => (e: any) => {
  e.stopPropagation();
  if (_.isFunction(callback)) {
    callback();
  }
};
