import { Action } from '../../models';

export const commonActions = {
  INIT: '@@init',
  AAD_LOGIN_SUCCESS: 'AAD_LOGIN_SUCCESS',
  API: 'AVSolutions/common/AVSolutions_API',
  DOWNLOAD_FILE: 'AVSolutions/common/DOWNLOAD_FILE',
  LOGOUT_USER: 'AVSolutions/auth/logoutUser',
  UNAUTHORIZED: 'AVSolutions/auth/unauthorized',
};

export const unauthorized = (): Action => {
  return {
    type: commonActions.UNAUTHORIZED,
  };
};
