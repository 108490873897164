import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardPage from '../Pages/Dashboard/DashboardPage';
import PickupPage from '../Pages/Pickup/PickupPage';
import DownloadsPage from '../Pages/Downloads/DownloadsPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={DashboardPage} />
    <Route exact path="/Pickup" component={PickupPage} />
    <Route exact path="/Downloads" component={DownloadsPage} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
