import appConfig from '../../config';
import { Action, ApiAction } from '../../models';
import { commonActions } from '../common/actions';
import { SuperAgent } from 'superagent';

export const productActions = {
  TOGGLE_PRODUCT_SELECTED: 'SWH/products/toggleProductSelected',

  GET_ALL_PRODUCTS_WITH_STATS_REQUEST: 'SWH/products/GetAllProductsWithStatsRequest',
  GET_ALL_PRODUCTS_WITH_STATS_SUCCESS: 'SWH/products/GetAllProductsWithStatsSuccess',
  GET_ALL_PRODUCTS_WITH_STATS_FAILURE: 'SWH/products/GetAllProductsWithStatsFailure',
};

export const toggleProductSelected = (productId: number): Action => {
  return {
    type: productActions.TOGGLE_PRODUCT_SELECTED,
    payload: {
      productId: productId,
    },
  };
};

export const getAllProductsWithStats = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/api/v1/Products/GetAllProductsWithStats`)
          .query({ includeSpecialOrderProducts: true });
      },
      beforeRequest: (): Action => ({ type: productActions.GET_ALL_PRODUCTS_WITH_STATS_REQUEST }),
      success: (data: any): Action => ({ type: productActions.GET_ALL_PRODUCTS_WITH_STATS_SUCCESS, payload: { products: data } }),
      failure: (response: any): Action => ({ type: productActions.GET_ALL_PRODUCTS_WITH_STATS_FAILURE, payload: { ...response } }),
    },
  };
};
