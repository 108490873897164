import { Dispatch, MiddlewareAPI } from 'redux';
import { ApiAction } from '../models';
import { RootState } from '../Modules/reducers';
import { base64toBlob, downloadXls, getYyyyMmDdString } from '../helpers/fileHelpers';

const exportDownloadMiddleware = ({ dispatch, getState }: MiddlewareAPI<any, RootState>) => (next: Dispatch) => async (action: ApiAction) => {
  if (!action.meta || !action.meta.file) {
    return next(action);
  }

  downloadXls(`${getYyyyMmDdString()}-SWH-ProductStock.xls`, base64toBlob(action.meta.file, 'application/vnd.ms-excel'));
};

export default exportDownloadMiddleware;
