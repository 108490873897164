import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

interface LocalProps {
  value: any;
  options: any[];
  disabled?: boolean;
  vertical?: boolean;
  block?: boolean;
  baseClassName?: string;
  defaultClassName?: string;
  primaryClassName?: string;
  buttonProps?: any;
  stopPropagation?: boolean;
  onClick: (value: any, option: any, e: any) => void;
}

class ButtonGroup extends React.PureComponent<LocalProps> {
  private onClick = (value: any, option: any, e: any): void => {
    const { onClick, stopPropagation } = this.props;

    if (stopPropagation) {
      e.stopPropagation();
    }

    if (!_.isFunction(onClick)) {
      return;
    }

    onClick(value, option, e);
  };

  public render (): any {
    const { value: selectedValue, disabled, options, vertical, block, baseClassName, defaultClassName, primaryClassName, buttonProps } = this.props;

    return (
      <div className={classnames({ 'btn-group': !vertical, 'btn-group-vertical': vertical, 'btn-block': block })}>
        {
          _.map(options, (option) => {
            const { label, value } = option;
            const btnClass = classnames({
              [baseClassName || 'btn']: true,
              [defaultClassName || 'btn-light']: value !== selectedValue,
              [primaryClassName || 'btn-primary']: value === selectedValue,
            });

            return (
              <button key={value} disabled={disabled} type="button" className={btnClass} onClick={(e): void => this.onClick(value, option, e)} {...buttonProps}>{label}</button>
            );
          })
        }
      </div>
    );
  }
}

export default ButtonGroup;
