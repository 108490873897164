import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { connect } from 'react-redux';

import { RootState } from '../../Modules/reducers';
import { OrderProductXref, OrderWithProducts } from '../../models';
import OrderPdfGenerator from '../../helpers/OrderPdfGenerator';
import { markOrderPickedUp, markOrderProductsPickedUp } from '../../Modules/orders/actions';
import CollapsibleCard from '../../Components/CollapsibleCard';
import { stopPropagationAnd } from '../../helpers/eventHelpers';
import ButtonGroup from '../../Components/ButtonGroup';
import { yesNoOptions } from './PickupPage';

interface LocalProps {
  isShowing: boolean;
  orderDetails: OrderWithProducts;
}

interface LocalState {
  selectedProductIds: {[productId: number]: any};
  isPrintingOrder: boolean;
}

interface StateProps {}

interface DispatchProps {
  markOrderPickedUp: typeof markOrderPickedUp;
  markOrderProductsPickedUp: typeof markOrderProductsPickedUp;
}

type Props = LocalProps & StateProps & DispatchProps;

class SelectedOrderPickup extends React.Component<Props, LocalState> {
  constructor (props: Props) {
    super(props);

    this.state = {
      isPrintingOrder: false,
      selectedProductIds: {},
    };
  }

  public componentDidUpdate (prevProps: Readonly<Props>): void {
    if (prevProps.orderDetails !== this.props.orderDetails) {
      this.setState({ selectedProductIds: {} });
    }
  }

  private printOrder = (products: OrderProductXref[]) => () => {
    const { isPrintingOrder } = this.state;
    const { orderDetails } = this.props;

    if (isPrintingOrder || !orderDetails) {
      return;
    }

    this.setState({ isPrintingOrder: true });
    OrderPdfGenerator.generatePdf({
      ...orderDetails,
      products: products,
    });
    this.setState({ isPrintingOrder: false });
  };

  private getNonPickedUpProducts = () => {
    const { orderDetails } = this.props;

    return _.filter(orderDetails.products, p => !p.pickupTimestamp);
  };

  private getSelectedProducts = () => {
    const { selectedProductIds } = this.state;
    const { orderDetails } = this.props;

    return _.filter(orderDetails.products, p => _.has(selectedProductIds, p.productId));
  };

  private getSelectedNonPickedUpProducts = () => {
    const { selectedProductIds } = this.state;
    const nonPickedUpProducts = this.getNonPickedUpProducts();

    return _.filter(nonPickedUpProducts, p => _.has(selectedProductIds, p.productId));
  };

  private getPickedUpProducts = () => {
    const { orderDetails } = this.props;

    return _.filter(orderDetails.products, p => !!p.pickupTimestamp);
  };

  private toggleProduct = (orderProduct: OrderProductXref) => () => {
    if (!(orderProduct.orderId > 0)) {
      return;
    }

    const selectedProductIds = { ...this.state.selectedProductIds };

    if (_.has(selectedProductIds, orderProduct.productId)) {
      delete selectedProductIds[orderProduct.productId];
    } else {
      selectedProductIds[orderProduct.productId] = true;
    }

    this.setState({ selectedProductIds });
  };

  private printAllAndMarkedPickedUp = () => {
    const selectedProducts = this.getNonPickedUpProducts();

    this.printProductsAndMarkedPickedUp(selectedProducts);
  };

  private printAndMarkedPickedUp = () => {
    const selectedProducts = this.getSelectedNonPickedUpProducts();

    this.printProductsAndMarkedPickedUp(selectedProducts);
  };

  private printProductsAndMarkedPickedUp = (products: OrderProductXref[]) => {
    const { orderDetails } = this.props;

    if (_.isEmpty(products)) {
      return;
    }

    this.props.markOrderProductsPickedUp(orderDetails.orderId, _.map(products, p => p.productId), true, () => {
      this.printOrder(products)();
    });
  };

  private renderPendingProducts = () => {
    const { selectedProductIds } = this.state;
    const products = this.getNonPickedUpProducts();

    return (
      <div className="order-products">
        {
          _.map(products, (product) => {
            const isSelected = _.has(selectedProductIds, product.productId);
            return (
              <div key={product.productId} className={classnames({ 'order-product-row': true, 'selected-row': isSelected })}>
                <div className="d-flex py-3 px-3 child-spacing-x-3">
                  <div>
                    <button className={classnames({ 'btn': true, 'btn-light': !isSelected, 'btn-primary': isSelected })} onClick={this.toggleProduct(product)}>Select</button>
                  </div>
                  <div>
                    <div><strong>Name:</strong></div>
                    <div>{product.name}</div>
                  </div>
                  <div>
                    <div><strong>Size:</strong></div>
                    <div>{product.productSize}</div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  private renderPickedUpProducts = () => {
    const { selectedProductIds } = this.state;
    const { orderDetails } = this.props;
    const products = this.getPickedUpProducts();

    return (
      <div className="order-products">
        {
          _.map(products, (product) => {
            const isSelected = _.has(selectedProductIds, product.productId);

            return (
              <div key={product.productId} className="order-product-row">
                <div className="d-flex py-3 px-3 child-spacing-x-3">
                  <div>
                    <button className={classnames({ 'btn': true, 'btn-light': !isSelected, 'btn-primary': isSelected })} onClick={this.toggleProduct(product)}>Select</button>
                  </div>
                  <div>
                    <div><strong>Name:</strong></div>
                    <div>{product.name}</div>
                  </div>
                  <div>
                    <div><strong>Size:</strong></div>
                    <div>{product.productSize}</div>
                  </div>
                  <div className="d-flex flex-1 align-items-center justify-content-end">
                    <div>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={stopPropagationAnd(() => this.props.markOrderProductsPickedUp(orderDetails.orderId, [product.productId], false))}
                      >
                        Not Picked Up
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  public render () {
    const { isPrintingOrder } = this.state;
    const { orderDetails } = this.props;

    const nonPickedUpProducts = this.getNonPickedUpProducts();
    const pickedUpProducts = this.getPickedUpProducts();
    const selectedProducts = this.getSelectedProducts();
    const selectedNonPickedUpProducts = this.getSelectedNonPickedUpProducts();

    return (
      <>
        <Row>
          <Col md={12}>
            <Row className="my-2">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <div>
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color="primary">
                        {!isPrintingOrder && (
                          <span>Print</span>
                        )}
                        {isPrintingOrder && (
                          <span><i className="fa fa-spin fa-cog" /> Printing...</span>
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.printOrder(orderDetails.products)}>Print Full Order</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem disabled={_.isEmpty(nonPickedUpProducts)} onClick={this.printOrder(nonPickedUpProducts)}>Print Non-Picked Up Products ({_.size(nonPickedUpProducts)})</DropdownItem>
                        <DropdownItem disabled={_.isEmpty(pickedUpProducts)} onClick={this.printOrder(pickedUpProducts)}>Print Picked Up Products ({_.size(pickedUpProducts)})</DropdownItem>
                        <DropdownItem disabled={_.isEmpty(selectedProducts)} onClick={this.printOrder(selectedProducts)}>Print Selected Products ({_.size(selectedProducts)})</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                  <div>
                    <div className="d-flex child-spacing-x-2">
                      <strong>Order Picked Up:</strong>
                      <ButtonGroup
                        stopPropagation
                        value={!!orderDetails.pickupTimestamp}
                        baseClassName="btn btn-sm"
                        primaryClassName={orderDetails.pickupTimestamp ? 'btn-success' : 'btn-danger'}
                        defaultClassName="btn-light"
                        onClick={(pickedUp: boolean) => this.props.markOrderPickedUp(orderDetails.orderId, pickedUp)}
                        buttonProps={{ tabIndex: -1 }}
                        options={yesNoOptions}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <CollapsibleCard
                  Header={(
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        Non-Picked Up Products ({_.size(nonPickedUpProducts)})
                      </div>
                      <div className="child-spacing-x-2">
                        <Button color="primary" size="sm" disabled={_.isEmpty(nonPickedUpProducts)} onClick={stopPropagationAnd(this.printAllAndMarkedPickedUp)}>
                          Print and Mark All ({_.size(nonPickedUpProducts)})
                        </Button>
                        {_.size(selectedNonPickedUpProducts) > 0 && (
                          <Button color="primary" size="sm" disabled={_.isEmpty(selectedNonPickedUpProducts)} onClick={stopPropagationAnd(this.printAndMarkedPickedUp)}>
                            Print and Mark Picked Up ({_.size(selectedNonPickedUpProducts)})
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  defaultOpen
                >
                  {this.renderPendingProducts()}

                  {_.size(nonPickedUpProducts) === 0 && (
                    <h5 className="text-center">All Products are Picked Up</h5>
                  )}
                </CollapsibleCard>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={12}>
                <CollapsibleCard
                  Header={(
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        Picked Up Products ({_.size(pickedUpProducts)})
                      </div>
                      <div />
                    </div>
                  )}
                >
                  {this.renderPickedUpProducts()}

                  {_.size(pickedUpProducts) === 0 && (
                    <h5 className="text-center">All Products are Not Picked Up</h5>
                  )}
                </CollapsibleCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = {
  markOrderPickedUp,
  markOrderProductsPickedUp,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(SelectedOrderPickup);
