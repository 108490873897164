import appConfig from '../../config';
import { Action, ApiAction } from '../../models';
import { commonActions } from '../common/actions';
import { SuperAgent } from 'superagent';

export const exportActions = {
  GET_CURRENT_SEASON_ORDERS_WITH_PRODUCTS_REQUEST: 'SWH/exports/GetCurrentSeasonOrdersWithProductsRequest',
  GET_CURRENT_SEASON_ORDERS_WITH_PRODUCTS_SUCCESS: 'SWH/exports/GetCurrentSeasonOrdersWithProductsSuccess',
  GET_CURRENT_SEASON_ORDERS_WITH_PRODUCTS_FAILURE: 'SWH/exports/GetCurrentSeasonOrdersWithProductsFailure',
};

export const exportCurrentSeasonOrdersWithProducts = (): ApiAction => {
  return {
    type: commonActions.API,
    payload: {
      getRequest: (superagent: SuperAgent<any>): any => {
        return superagent.get(`${appConfig.baseUrl}/Exports/GetCurrentSeasonOrdersWithProducts`);
      },
      beforeRequest: (): Action => ({ type: exportActions.GET_CURRENT_SEASON_ORDERS_WITH_PRODUCTS_REQUEST }),
      success: (data: any): Action => ({ type: exportActions.GET_CURRENT_SEASON_ORDERS_WITH_PRODUCTS_SUCCESS, meta: { file: data } }),
      failure: (response: any): Action => ({ type: exportActions.GET_CURRENT_SEASON_ORDERS_WITH_PRODUCTS_FAILURE, payload: { ...response } }),
    },
  };
};
