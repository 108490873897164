import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { RootState } from '../../Modules/reducers';
import CustomerViewAutoHide from '../../SWH/CustomerViewAutoHide';
import { exportCurrentSeasonOrdersWithProducts } from '../../Modules/exports/actions';

interface LocalProps {}

interface LocalState {}

interface StateProps {}

interface DispatchProps {
  exportCurrentSeasonOrdersWithProducts: typeof exportCurrentSeasonOrdersWithProducts;
}

type Props = LocalProps & StateProps & DispatchProps;

class DashboardPage extends React.Component<Props, LocalState> {
  public render () {
    return (
      <Row>
        <Col md={4}>
          <CustomerViewAutoHide>
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <span>All Orders and Products for Current Season</span>
                </div>
              </div>
              <div className="card-body">
                <p>This is basically a data dump of all the orders for the current season with the products associated to them all on one spreadsheet</p>
                <button className="btn btn-primary" onClick={this.props.exportCurrentSeasonOrdersWithProducts}>Export</button>
              </div>
            </div>
          </CustomerViewAutoHide>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
  };
};

const mapDispatchToProps = {
  exportCurrentSeasonOrdersWithProducts,
};

export default connect<StateProps, DispatchProps, LocalProps, RootState>(mapStateToProps, mapDispatchToProps)(DashboardPage);
