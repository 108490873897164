import { Action } from '../../models';
import { orderActions } from '../orders/actions';
import { productActions } from '../products/actions';

export interface LoadingState {
  isLoadingOrdersForSeason: boolean;
  isLoadingOrderDetails: boolean;
  isLoadingAllProductsWithStats: boolean;
  isUpdatingOrder: boolean;
}

const initState: LoadingState = {
  isLoadingOrdersForSeason: false,
  isLoadingOrderDetails: false,
  isLoadingAllProductsWithStats: false,
  isUpdatingOrder: false,
};

export default (state = initState, action: Action): LoadingState => {
  switch (action.type) {
    case orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_REQUEST: {
      return {
        ...state,
        isLoadingOrdersForSeason: true,
      };
    }
    case orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_SUCCESS:
    case orderActions.GET_ALL_ORDERS_FOR_CURRENT_SEASON_FAILURE: {
      return {
        ...state,
        isLoadingOrdersForSeason: false,
      };
    }

    case orderActions.GET_ORDER_DETAILS_BY_ID_REQUEST: {
      return {
        ...state,
        isLoadingOrderDetails: true,
      };
    }
    case orderActions.GET_ORDER_DETAILS_BY_ID_SUCCESS:
    case orderActions.GET_ORDER_DETAILS_BY_ID_FAILURE: {
      return {
        ...state,
        isLoadingOrderDetails: false,
      };
    }

    case orderActions.UPDATE_ORDER_REQUEST: {
      return {
        ...state,
        isUpdatingOrder: true,
      };
    }
    case orderActions.UPDATE_ORDER_SUCCESS:
    case orderActions.UPDATE_ORDER_FAILURE: {
      return {
        ...state,
        isUpdatingOrder: false,
      };
    }

    case productActions.GET_ALL_PRODUCTS_WITH_STATS_REQUEST: {
      return {
        ...state,
        isLoadingAllProductsWithStats: true,
      };
    }
    case productActions.GET_ALL_PRODUCTS_WITH_STATS_SUCCESS:
    case productActions.GET_ALL_PRODUCTS_WITH_STATS_FAILURE: {
      return {
        ...state,
        isLoadingAllProductsWithStats: false,
      };
    }
    default: {
      return state;
    }
  }
};
