import React from 'react';
import { NavLink } from 'react-router-dom';

interface LocalProps {

}

const Sidebar = () => (
  <div id="layoutSidenav_nav">
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <NavLink exact className="nav-link" to="/">
            <div className="sb-nav-link-icon"><i className="fa fa-tachometer" /></div>
            Dashboard
          </NavLink>
          <NavLink className="nav-link" to="/Pickup">
            <div className="sb-nav-link-icon"><i className="fa fa-tachometer" /></div>
            Pickups
          </NavLink>
          <NavLink className="nav-link" to="/Downloads">
            <div className="sb-nav-link-icon"><i className="fa fa-download" /></div>
            Downloads
          </NavLink>
        </div>
      </div>
    </nav>
  </div>
);

export default Sidebar;
