import { createSelector } from 'reselect';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { RootState } from '../reducers';
import { Order } from '../../models';

const getOrders = (state: RootState) => state.orders.orders;

export const getSortedOrders = createSelector([getOrders], (orders): Order[] => {
  return _.orderBy(orders, o => o.createdTimestamp, 'desc');
});

export const getOrdersIndex = createSelector([getOrders], (orders): Fuse<Order, { keys: string[] }> => {
  const options = {
    keys: ['name', 'email', 'phoneNumber', 'workPhoneNumber', 'comments'],
    threshold: 0.2,
  };

  return new Fuse(_.values(orders) || [], options);
});
