import React, { ReactNode, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { siteActions } from './Modules/site/actions';
import { RootState } from './Modules/reducers';
import toastr from 'toastr';

interface Props {
  children?: ReactNode;
}

const Login = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { user, getAccessTokenWithPopup } = useAuth0();
  const userIsLoaded = useSelector((state: RootState) => !!state.site.token);

  useEffect(() => {
    if(userIsLoaded) {
      toastr.success('Logged in successfully, loading products...');
    }
  }, [userIsLoaded])

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenWithPopup({
        scope: 'openid email profile',
      });

      dispatch({ type: siteActions.LOGIN_USER_SUCCESS, payload: token });
    })();
  }, [user, getAccessTokenWithPopup, dispatch]);

  if(!userIsLoaded) {
    return (
      <div className="d-flex flex-column text-center align-items-center justify-content-center vh-100">
        <div>
          <h1>Logging into SWH...</h1>
          <h4>You may need to allow popups in order to proceed.</h4>
          <h5>If its taking too long you may need to reload</h5>
        </div>
      </div>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default Login;
