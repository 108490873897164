import _ from 'lodash';
import { Action, OrderWithProducts, ProductWithStats } from '../../models';
import { productActions } from './actions';
import { orderActions } from '../orders/actions';

export interface ProductsState {
  products: {[productId: number]: ProductWithStats};
  selectedProducts: {[productId: number]: any};
}

const initState: ProductsState = {
  products: [],
  selectedProducts: {},
};

export default (state = initState, action: Action): ProductsState => {
  switch (action.type) {
    case productActions.TOGGLE_PRODUCT_SELECTED: {
      const { productId }: { productId: number } = action.payload;

      const selectedProducts = { ...state.selectedProducts };

      if (_.has(selectedProducts, productId)) {
        delete selectedProducts[productId];
      } else {
        selectedProducts[productId] = true;
      }

      return {
        ...state,
        selectedProducts: selectedProducts
      };
    }
    case productActions.GET_ALL_PRODUCTS_WITH_STATS_SUCCESS: {
      const { products }: { products: ProductWithStats[] } = action.payload;

      return {
        ...state,
        products: _.keyBy(products, p => p.productId),
      };
    }
    case orderActions.GET_ORDER_DETAILS_BY_ID_SUCCESS: {
      const { order }: { order: OrderWithProducts } = action.payload;

      const products = { ...state.products };

      _.each(order.products, (product) => {
        if (!_.has(products, product.productId)) {
          return;
        }

        products[product.productId] = {
          ...products[product.productId],
          numSold: product.numSold || 0,
          displayNumSold: product.displayNumSold || '',
          currentStock: product.currentStock || 0,
          displayCurrentStock: product.displayCurrentStock || '',
          calculatedStock: product.calculatedStock || 0,
          displayCalculatedStock: product.displayCalculatedStock || '',
          productRevenue: product.productRevenue || 0,
          displayProductRevenue: product.displayProductRevenue || '',
        };
      });

      return {
        ...state,
        products: products,
      };
    }
    default: {
      return state;
    }
  }
};
